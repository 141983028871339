import { GET_BIKE_DATA, GET_BIKE_DATA_ERROR } from "./types";
import axios from "axios";
import moment from "moment";

const number_of_positions_points = 30;
const paramBikeData = {
  devices: {},
  is_last_value: true,
  data_types: ["position"],
  from_timestamp: "",
  to_timestamp: "",
  aggregate_operation_name: null,
  aggregate_period_name: null,
};
const headers = {
  "Content-Type": "application/json",
  Authorization: "APIKEY MORIOKEY0001",
};

let lastTimeStamp;

export const getBikeData = (deviceData = null) => {
  if (deviceData != null) {
    paramBikeData.devices[deviceData.device_ref] = "";
    return (dispatch) => {
      paramBikeData.is_last_value = true;
      getLastPositionTimestamp(deviceData)
        .then((response) => {
          // response is the Last value of a device
          lastTimeStamp = response.data[0].data_points[0].timestamp;
          paramBikeData.from_timestamp = moment
            .unix(lastTimeStamp)
            .subtract(5, "days")
            .format("YYYY-MM-DD HH:mm");
          paramBikeData.to_timestamp = moment
            .unix(lastTimeStamp)
            .format("YYYY-MM-DD HH:mm");
          paramBikeData.is_last_value = false;

          return axios.post("/device_data", paramBikeData, {
            headers: headers,
          });
        })
        .then((response) => {
          // response is the last 3 days values of a device
          if (response.data[0].data_points.length > 0) {
            dispatch(createPostSuccess(response.data));
          } else {
            // dispatch error
            console.log("no data for this device");
          }
        });
    };
  } else {
    console.log("no device");
    //dispatch error
  }
};

//---------------------------------------------------------------------------------------

export const getLastPositionTimestamp = (deviceDataRef = null) => {
  if (deviceDataRef) {
    return axios.post("/device_data", paramBikeData, {
      headers: headers,
    });
  } else {
    // dispatch error
    console.log("no device");
  }
};

//---------------------------------------------------------------------------------------

export const createPostSuccess = (data) => {
  var donnees = data[0].data_points.slice(-number_of_positions_points);
  return {
    type: GET_BIKE_DATA,
    payload: {
      device_ref: data[0].device_reference,
      lastLatitude: donnees[donnees.length - 1].position_latitude,
      lastLongitude: donnees[donnees.length - 1].position_longitude,
      dataPoints: donnees,
      lastPositionTime: moment.unix(lastTimeStamp).format("DD-MM-YYYY HH:mm "),
    },
  };
};

export const createPostError = (data) => {
  return {
    type: GET_BIKE_DATA_ERROR,
    payload: {},
  };
};
