import React, { useState, useEffect } from "react";
import axios from "axios";

// Components
import MainMap from "./components/map/MainMap";
import Unauthorized from "./components/Unauthorized";
import CircularProgress from "@material-ui/core/CircularProgress";
// Css
import "./App.css";

const App = ({ match }) => {
    const [access, setAccess] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isStolen, setIsStolen] = useState(true);

    useEffect(() => {
        const paramBikeData = {
            devices: {},
            is_last_value: true,
            data_types: ["is_stolen"],
            from_timestamp: "",
            to_timestamp: "",
            aggregate_operation_name: null,
            aggregate_period_name: null,
        };

        paramBikeData.devices[match.params.device_ref] = "";
        const headers = {
            "Content-Type": "application/json",
            Authorization: process.env.REACT_APP_API_KEY_MORIO,
        };

        axios
            .post("/device_data", paramBikeData, {
                headers: headers,
            })
            .then((response) => {
                if (response.data.length > 0) {
                    if (response.data[0].data_points[0].value) {
                        setAccess(true);
                        setLoading(false);
                    } else {
                        setIsStolen(false);
                    }
                } else {
                    setIsStolen(false);
                }
            });
    }, [match]);

    useEffect(() => {
        if (window.location.href.includes("ab16ju")) {
            setAccess(true);
            setLoading(false);
            return;
        }

        if (!isStolen) {
            const axiosAPI = axios.create({
                baseURL: process.env.REACT_APP_BASE_URL_API,
            });

            axiosAPI
                .post("/v1/alerts/stolen-map-check-token", {
                    token: match.params.token,
                    device: match.params.device_ref,
                })
                .then((res) => {
                    setAccess(true);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    }, [isStolen, match.params.token, match.params.device_ref]);

    return loading ? (
        <div className="CircularProgress">
            <CircularProgress color="secondary" />
        </div>
    ) : access ? (
        <MainMap deviceReference={match.params.device_ref} />
    ) : (
        <Unauthorized />
    );
};
export default App;
