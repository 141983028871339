// postReducer.js

import { GET_BIKE_DATA } from "../actions/types";

const initialState = {};

export default function bikeDataReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BIKE_DATA:
      return {
        ...state,
        device_ref: action.payload.device_ref,
        lastLatitude: action.payload.lastLatitude,
        lastLongitude: action.payload.lastLongitude,
        dataPoints: action.payload.dataPoints,
        lastTimeStamp: action.payload.lastPositionTime
      };
    default:
      return state;
  }
}
