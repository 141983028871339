import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import {
  createMuiTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@material-ui/core/styles";
//Redux
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
let theme = createMuiTheme({
  palette: {
    primary: {
      light: "#54c5b8",
      main: "#45b2a5",
      dark: "#44ab9f",
      contrastText: "#fff",
    },
    secondary: {
      light: "#fd6442",
      main: "#fa512c",
      dark: "#dc583b",
      contrastText: "#fff",
    },
  },
});

theme = responsiveFontSizes(theme);
const store = createStore(rootReducer, applyMiddleware(thunk));
// const splittedUrl = window.location.href.split(".");
// if (splittedUrl.length > 1) {
//   splittedUrl.splice(splittedUrl.length - 1, 1);
//   splittedUrl[0] =
//     splittedUrl[1] === "local" ? "http://api-morio" : "https://api-morio";
//   splittedUrl.push("co");
//   const url = splittedUrl.join(".");
//   axios.defaults.baseURL = `${url}/iot_rocket`;
// } else {
//   axios.defaults.baseURL = "http://api-morio.local.morio.co/iot_rocket";
// }

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL_API_MORIO;
// const device_reference = window.location.href.split("/");

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Switch>
          <Route exact path="/:device_ref/:token?" component={App} />
        </Switch>
      </ThemeProvider>
    </Provider>
  </Router>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
