import React from "react";
import "./Popup.css";
import ArrowBackIcon from "@material-ui/icons/ArrowBack"; //Google Map Api
import Button from "@material-ui/core/Button";

import pngFile from "../assets/que-faire-en-cas-vol.png";

function PopupPng({ handlePopupVisibility }) {
  return (
    <div className="map-popup-wrapper">
      <div
        onClick={() => handlePopupVisibility(false)}
        className="map-popup-button-wrapper"
      >
        <Button
          target="_blank"
          color="secondary"
          variant="contained"
          className="button-popup"
        >
          <ArrowBackIcon
            style={{
              fontSize: "1rem",
              marginRight: "5px",
            }}
          />
          <span>Retour</span>
        </Button>
      </div>

      <div
        className="document-wrapper"
        style={{ backgroundImage: `url(${pngFile}) ` }}
      >
        <a
          className="link-tuto"
          href={process.env.REACT_APP_NRF_CONNECT_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          dsqdss
        </a>
      </div>
    </div>
  );
}

export default PopupPng;
