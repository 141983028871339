import React from "react";
import bikeSvg from "../assets/undraw_bike_ride_7xit.svg";
import "./Unauthorized.css";

export default function unauthorized() {
  return (
    <div className="unauthorized-wrapper">
      <img className="unauthorized-bike" src={bikeSvg} alt="bike" />
      <p style={{ fontWeight: 500, textAlign: "center" }}>
        Il semblerait que votre vélo soit en sécurité
      </p>
      <p style={{ marginTop: 0, fontSize: "smaller" }}>
        Vous n’avez pas accès à cette page
      </p>
    </div>
  );
}
