/*global google*/

import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/fr";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import "./mainMap.css";
import {
  Map,
  GoogleApiWrapper,
  Marker,
  Polyline,
  InfoWindow,
} from "google-maps-react";

import PopupPng from "../PopupPng";
// Actions
import { getBikeData } from "../../actions/index";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

const apiKey = process.env.REACT_APP_API_KEY_GOOGLE;

class MainMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      firstMount: true,
      time: "",
      bikeData: {},
      mapCenter: {},
      refs: { map: undefined },
      loading: true,
      zoom: 15,
      displayMap: false,
      name: "",
      positions: [],
      lastPosition: {
        lat: 43.545344,
        lng: 1.513944,
      },
      lastTimeStamp: "",
      selectedPlace: {},

      infoWindow: {
        showingInfoWindow: false,
        activeMarker: {},
        markerTimestamp: "",
      },
    };
  }

  componentDidMount() {
    this.getBikeDatas();
    this.interval = setInterval(() => {
      this.setState({
        firstMount: false,
      });
      this.getBikeDatas();
    }, 30000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.bikeData !== prevState.bikeData) {
      return { bikeData: nextProps.bikeData };
    } else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.bikeData !== this.props.bikeData) {
      this.setState({
        name: this.props.bikeData.bikeData.device_ref,
        positions: this.props.bikeData.bikeData.dataPoints,
        lastPosition: {
          lat: this.props.bikeData.bikeData.lastLatitude,
          lng: this.props.bikeData.bikeData.lastLongitude,
        },
        lastTimeStamp: this.props.bikeData.bikeData.lastTimeStamp,
      });
      if (this.state.firstMount) {
        this.setState({
          mapCenter: {
            lat: this.props.bikeData.bikeData.lastLatitude,
            lng: this.props.bikeData.bikeData.lastLongitude,
          },
        });
      }
    }
  }

  /**
   * Retrieve the map reference
   * and initialize the map view
   */
  getRef = (ref) => {
    if (!this.state.refs.map && ref) {
      this.setState({
        refs: { map: ref },
      });
    }
  };

  getBikeDatas = () => {
    this.props.getBikeData({
      device_ref: this.props.deviceReference,
    });
  };

  zoomOnMarker = () => {
    this.setState({
      mapCenter: this.state.lastPosition,
      zoom: 20,
    });
  };

  onMarkerClick = (props, marker, e, index) => {
    var address = "";
    var city = "";

    (async () => {
      await axios
        .get(
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
            this.state.positions[index].position_latitude +
            "," +
            this.state.positions[index].position_longitude +
            "&key=" +
            apiKey
        )
        .then((res) => {
          if (res && res.data.results.length > 0) {
            address = res.data.results[0].formatted_address.split(", ")[0];
            //address = res.data.results[0].address_components[0].short_name + ", " + res.data.results[0].address_components[1].short_name;
            city = res.data.results[0].formatted_address.split(", ")[1];
          } else {
            address = "Lieu";
            city = "inconnu";
          }
        });

      this.setState({
        zoom: this.state.refs.map.zoom,
        mapCenter: this.state.refs.map.map.center,
      });
      moment.locale("fr");
      this.setState({
        selectedPlace: props,
        infoWindow: {
          adresse: address,
          ville: city,
          showingInfoWindow: true,
          activeMarker: marker,
          markerTimestamp: moment
            .unix(this.state.positions[index].timestamp)
            .format("LLLL"),
        },
        zoom: 16,
        mapCenter: marker.position,
      });
    })();
  };

  onMapClicked = (props) => {
    if (this.state.infoWindow.showingInfoWindow) {
      this.setState({
        infoWindow: {
          showingInfoWindow: false,
          activeMarker: null,
        },
      });
    }
  };

  handlePopupVisibility = (show) => {
    this.setState({ showPopup: show });
  };

  render() {
    const mapStyles = {
      width: "100%",
      height: "100%",
    };

    const color = { lastPosition: "#FF0000", previewPosition: "#45b2a5" };
    const lastPosition = (
      <Marker
        onClick={(props, marker, e) =>
          this.onMarkerClick(props, marker, e, this.state.positions.length - 1)
        }
        title={""}
        name={""}
        position={this.state.lastPosition}
        zIndex={1000}
        icon={{
          path: "M32 2a20 20 0 0 0-20 20c0 18 20 40 20 40s20-22 20-40A20 20 0 0 0 32 2zm0 28a8 8 0 1 1 8-8 8 8 0 0 1-8 8zM32 2a20 20 0 0 0-20 20c0 18 20 40 20 40s20-22 20-40A20 20 0 0 0 32 2zm0 28a8 8 0 1 1 8-8 8 8 0 0 1-8 8z",
          fillColor: color.lastPosition,
          fillOpacity: 1,
          strokeWeight: 0,
          scale: 0.7,
          anchor: new google.maps.Point(33, 60),
        }}
      ></Marker>
    );

    let positions = this.state.positions.map((item, index) => {
      if (index !== this.state.positions.length - 1) {
        return (
          <Marker
            onClick={(props, marker, e) =>
              this.onMarkerClick(props, marker, e, index)
            }
            key={index}
            title={"The marker`s title will appear as a tooltip."}
            name={"SOMA"}
            position={{
              lat: item.position_latitude,
              lng: item.position_longitude,
            }}
            icon={{
              path: google.maps.SymbolPath.CIRCLE,
              fillColor: "#45b2a5",
              fillOpacity: 1,
              strokeColor: "#5c5c5c",
              strokeOpacity: 0.9,
              strokeWeight: 2,
              scale: 4,
            }}
          />
        );
      }
      return "";
    });

    const path = this.state.positions.map((item, index) => {
      return { lat: item.position_latitude, lng: item.position_longitude };
    });

    const MapContainer = this.state.bikeData ? (
      <Map
        onClick={this.onMapClicked}
        google={this.props.google}
        zoom={this.state.zoom}
        style={mapStyles}
        center={this.state.mapCenter}
        ref={(ref) => this.getRef(ref)}
        containerStyle={{ position: "fixed", height: "94%" }}
      >
        <Polyline
          path={path}
          options={{
            strokeColor: "#5c5c5c",
            strokeWeight: 2,
          }}
        />
        {lastPosition}
        {positions}
        <InfoWindow
          marker={this.state.infoWindow.activeMarker}
          visible={this.state.infoWindow.showingInfoWindow}
        >
          <div>
            <p style={{ textAlign: "center" }}>
              <b>{this.state.infoWindow.adresse}</b>
            </p>
            <p style={{ textAlign: "center" }}>
              <b>{this.state.infoWindow.ville}</b>
            </p>
            <p style={{ textAlign: "center" }}>
              {this.state.infoWindow.markerTimestamp}
            </p>
          </div>
        </InfoWindow>
      </Map>
    ) : (
      <div style={{ backgroundColor: "black" }}></div>
    );

    return (
      <div className="map-wrapper">
        <div className="map-nav-wrapper">
          <Box display={{ xs: "none", sm: "block" }}>
            <ReportProblemIcon className="map-nav-problem-icon" />
          </Box>
          <Typography variant="h6">Que faire en cas de vol ?</Typography>
          <Button
            onClick={() => this.handlePopupVisibility(true)}
            color="secondary"
            variant="contained"
            style={{
              marginLeft: "10px",
              color: "white",
            }}
          >
            Cliquez ici
          </Button>
        </div>

        {MapContainer}
        {this.state.showPopup && (
          <PopupPng handlePopupVisibility={this.handlePopupVisibility} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  bikeData: state,
});

const mapDispatchToProps = (dispatch) => ({
  getBikeData: (deviceReference) => dispatch(getBikeData(deviceReference)),
});

export default GoogleApiWrapper({
  apiKey: apiKey,
})(connect(mapStateToProps, mapDispatchToProps)(MainMap));
